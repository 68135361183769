import { Grid, Typography, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/InfoOutlined";

const Header = () => {
  return (
    <Grid container alignItems="center" width="100%">
      <Grid item xs>
        <Typography variant="h5" color="text.primary">
          My Files
        </Typography>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Button
              component="label"
              variant="contained"
              startIcon={<AddIcon />}
            >
              New file
            </Button>
          </Grid>
          <Grid item>
            <IconButton sx={{ display: { xs: "none", sm: "block" } }}>
              <InfoIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
