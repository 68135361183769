import { Grid, Button, ButtonGroup, IconButton } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WindowIcon from "@mui/icons-material/Window";

const ViewToggle = ({ selectedTab, setSelectedTab, viewMode, setViewMode }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Grid item>
        <ButtonGroup>
          <Button
            variant={selectedTab === "Folders" ? "contained" : "outlined"}
            onClick={() => setSelectedTab("Folders")}
            sx={{
              borderRadius: "50px",
              width: 100,
              backgroundColor:
                selectedTab === "Folders" ? "primary.main" : "inherit",
              color: selectedTab === "Folders" ? "white" : "inherit",
            }}
          >
            Folders
          </Button>
          <Button
            variant={selectedTab === "Files" ? "contained" : "outlined"}
            onClick={() => setSelectedTab("Files")}
            sx={{
              borderRadius: "50px",
              width: 100,
              backgroundColor:
                selectedTab === "Files" ? "primary.main" : "inherit",
              color: selectedTab === "Files" ? "white" : "inherit",
            }}
          >
            Files
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item>
        <ButtonGroup>
          <Button
            variant={viewMode === "List" ? "contained" : "outlined"}
            onClick={() => setViewMode("List")}
            sx={{
              height: "40px",
              padding: "4px 12px",
              backgroundColor: viewMode === "List" ? "primary.main" : "inherit",
              color: viewMode === "List" ? "white" : "inherit",
            }}
          >
            <IconButton sx={{ display: { xs: "none", sm: "block" } }}>
              <FormatListBulletedIcon />
            </IconButton>
          </Button>
          <Button
            variant={viewMode === "Grid" ? "contained" : "outlined"}
            onClick={() => setViewMode("Grid")}
            sx={{
              height: "40px",
              padding: "4px 12px",
              backgroundColor: viewMode === "Grid" ? "primary.main" : "inherit",
              color: viewMode === "Grid" ? "white" : "inherit",
            }}
          >
            <IconButton sx={{ display: { xs: "none", sm: "block" } }}>
              <WindowIcon />
            </IconButton>
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default ViewToggle;
