import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PageLoading from "./components/PageLoading";
import "./utilities/axios";

import Header from "./components/Header";
import Home from "./pages/home/Home";

const App = () => {
  return (
    <Header>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Suspense>
    </Header>
  );
};

export default App;
