import React, { useState } from "react";
import { Stack } from "@mui/material";
import Header from "./components/Header";
import ViewToggle from "./components/ViewToggle";
const Files = React.lazy(() => import("./components/files/Files"));
const Folder = React.lazy(() => import("./components/folder/Folder"));

const Home = () => {
  const [selectedTab, setSelectedTab] = useState("Files");
  const [viewMode, setViewMode] = useState("Grid");
  return (
    <Stack p={2} gap={3}>
      <Header />
      <ViewToggle
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        viewMode={viewMode}
        setViewMode={setViewMode}
      />
      {selectedTab === "Files" ? (
        <Files viewMode={viewMode} />
      ) : (
        <Folder viewMode={viewMode} />
      )}
    </Stack>
  );
};

export default Home;
